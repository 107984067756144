import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, Form, Col, InputGroup } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { useStaticQuery } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha'
import { graphql } from 'gatsby'
import Select from "react-select"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import closeBtn from '../assets/images/close-button.svg';

const schema = yup.object({
    username: yup.string().min(2, 'Name and last name too short.').required('Please write down your name and last name.'),
    // company: yup.string().required(),
    email: yup.string().email('Invalid email.').required('Please write down your email address.'),
    company: yup.string().required('Please write down your company name.'),
    reason: yup.string().required('Please write down your reason.'),
});

const settings = {
    dots:true,
    arrows:false,
    centerMode: true,
    autoplay: true,
    centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    fade:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
            centerPadding: "60px",
        }
      },
      {
        breakpoint: 768,
        settings: {
            centerPadding: "30px",
        }
      }
    ]
};

const selectStyles = {
    option: (styles) => {
      return {
        ...styles,
        color: '#000',
        fontSize:"14px",
        fontFamily:"HelveticaNeueLT Pro 55 Roman",
        backgroundColor:"#fff",
        cursor:"pointer"
      };
    },
};

export default function ContactUs(props) {
    const data = useStaticQuery(query);
    const { 
        allContentfulContactUsReasons: {
            nodes: opt
        },
        allContentfulTestimonials:{
            nodes:testimonials
        }
    } = data;

    const [resultForm, setResultForm] = useState("");
    // const reRef = useRef();
    const recaptchaRef = React.createRef();
    useEffect(() => {
        setResultForm("");
    }, [props.show]);
   
    const [status, setStatus] = useState("Contact Us");
    const [activeTestimonial, setActiveTestimonial] = useState(testimonials[0].logo.title);


    //FORM SUBMIT
    const handleSubmit = async (values, { resetForm }) => {

        // const token = await reRef.current.executeAsync();
        // const recaptchaValue = recaptchaRef.current.getValue()
        // reRef.current.reset();
        // console.log("Token of ReCAPTCHA: "+token);

        setStatus("Submiting...");

        const newValues = {
            'entry.772377252' : values.username,
            'entry.1285964399': values.company,
            'entry.1823105943': values.email,
            'entry.1651195108': values.reason
        }

        // data.append('g-recaptcha-response', recaptchaValue);

        const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLScnEvrnrnubaxL_W2d-Wr30YQidY8wLgRu8NwY9PgLXvMOmTg/formResponse?";      
        
        const params = new URLSearchParams(newValues)
        let url = baseUrl + params + "&submit=Submit";
        try {
            const response = await fetch(url, {
                method: "GET",  
                mode:"no-cors",
                headers: { "Content-Type": "application/x-www-form-urlencoded"}
            })
            resetForm({
                username: '',
                company: '',
                email: '',
                country: ''
            });
            setResultForm("Thanks for contacting us. You should hear back from our team.");
            setStatus("Submit");
        } catch (err) {
            // console.log(err,err.message);
            setStatus("Submit again!");
        }
    };

    const renderForm = (hidden) => {
        const style = hidden ? { display: 'none' } : {};

        return (
        <Formik
            initialValues={{
                username: '',
                company: '',
                email: '',
                reason:'',
                // contact: 'contact'
            }}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, handleChange, values, errors, setFieldValue, setErrors}) => (
                // data-netlify-honeypot="bot-field"
                <FormikForm style={style}>
                    {/* <input type="hidden" name="form-name" value={values.contact} /> */}
                    <Form.Row>
                        <Form.Group as={Col} md="6"  className="username-class" >
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="First and last name"
                                    aria-describedby="inputGroupPrepend"
                                    name="username"
                                    // id="username"
                                    value={values.username}
                                    onChange={(e) => {
                                        handleChange(e);
                                        let err = errors;
                                        delete err.username;
                                        setErrors(err)
                                        
                                    }}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.username ? (<p> {errors.username} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6"  className="company-field" >
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Company"
                                    aria-describedby="inputGroupPrepend"
                                    name="company"
                                    value={values.company}
                                    onChange={(e) => {
                                        handleChange(e);
                                        let err = errors;
                                        delete err.company;
                                        setErrors(err)
                                        
                                    }}
                                    isInvalid={!!errors.company}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.company ? (<p> {errors.company} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6"  className="email" >
                            <InputGroup>
                                <Form.Control
                                    type="email"
                                    placeholder="Work email"
                                    aria-describedby="inputGroupPrepend"
                                    name="email"
                                    value={values.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        let err = errors;
                                        delete err.email;
                                        setErrors(err)
                                        
                                    }}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email ? (<p> {errors.email} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="reason" >
                        <InputGroup>
                            <Select 
                                name="reason"
                                className={!!errors.reason ? "is-invalid" : "" }
                                classNamePrefix="reason-select"
                                options={opt.map((opt) => ({value:opt.reason,label:opt.reason}) )}
                                isSearchable={false}
                                placeholder="Reason for contacting"
                                styles={selectStyles}
                                onChange={(e) => { 
                                    setFieldValue("reason",e.value);
                                    let err = errors;
                                    delete err.reason;
                                    setErrors(err)
                                }}
                                value={
                                    values.reason.length
                                      ? {
                                          label: values.reason,
                                          value: values.reason,
                                        }
                                      : null
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.reason ? (<p> {errors.reason} </p>) : null}
                            </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" style={{ marginBottom: "10px" }}>
                            <Button type="submit" className="contact-us-btn" disabled={isSubmitting}>{status}</Button>
                        </Form.Group>
                        {resultForm !== "" ?
                            <Form.Group as={Col} md="12">
                                <p className="contact-result">{resultForm}</p>
                            </Form.Group> : null}
                        <Form.Group as={Col} md="12">
                            <p className="terms-privacy">By clicking the button, you agree to Sogody's <a href="/terms/">Terms of Service</a> and <a href="/privacy/">Privacy Policy</a>. </p>
                        </Form.Group>
                    </Form.Row>
                </FormikForm>
            )}
        </Formik>);
    }

    return (
        <>
            {renderForm(true)}
            <Modal
                {...props}
                dialogClassName="modal-ctn"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="close-modal"><button onClick={props.onHide}>Close <img src={closeBtn} alt="close button" /></button></div>
                <div class={`testimonials-ctn ${activeTestimonial}`}>
                    <Slider {...settings} beforeChange={(current,next) => setActiveTestimonial(testimonials[next].logo.title)}>
                        {
                            testimonials.map(({description,company,position,name,image,logo}) => {
                                return (
                                   <div className="testimonial">
                                       <div>
                                            <img src={logo.fluid.src} alt="company logo" className="company-logo"/>
                                            <p className="description">{description.description}</p>
                                       </div>

                                        <div className="person">
                                            <img src={image.fluid.src} alt="profile picture" />
                                            <p className="username">{name}</p>
                                            <p className="position">{position}, {company}</p>
                                        </div>
                                   </div>
                                )
                            })
                        }
                    </Slider>
                </div>
                <div className="modal-form">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Contact Sales at Sogody
                        </Modal.Title>
                        <p className="modal-header-paragraph">Looking for personalized help? In order to ensure you receive a quick reply, please fill out our Contact Us form.</p>
                    </Modal.Header>
                    <Modal.Body>
                        {renderForm()}
                        {/* <ReCAPTCHA  id='recaptcha' className="g-recaptcha" sitekey="6LewSV0aAAAAAG1_BrRo4FiqUz7FKKz4BC7Gg2EG" size="invisible" ref={recaptchaRef}/> */}
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}

export const query = graphql`
    {
        allContentfulContactUsReasons(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
        nodes {
            reason
        }
        }
        allContentfulTestimonials(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
            nodes {
              company
              image {
                fluid(quality: 100) {
                  src
                }
              }
              logo {
                fluid(quality: 100) {
                  src
                }
                title
              }
              name
              position
              description {
                description
              }
            }
        }
    }
`