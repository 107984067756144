import React from 'react'
import {useStaticQuery} from 'gatsby'
import { Row, Col, Container} from 'react-bootstrap';
import { graphql } from 'gatsby'
//  import 'bootstrap/dist/css/bootstrap.min.css' 
import ContactUs from './ContactUs';

const Footer = () => {
    const data = useStaticQuery(query);

    const [modalShow, setModalShow] = React.useState(false);

    const {allContentfulJobPositions:{}} = data

    const today = new Date();

    // const dateNew = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() ;
    // console.log(today);
    const careers = data.allContentfulJobPositions.nodes;
    // console.log(careers);
    const total= careers.filter(careers=> new Date(careers.date) >= today).length;

    return (
        <div className="footer">
        <Container>  
                <Row>
                    <Col xs={12} md={2} lg={2} className="footer-general" >
                        <p className="title-white"><a  onClick={() => setModalShow(true)} >General Enquiries</a></p>
                        <p className="title-white"> <a href="/careers/">Careers <span className="footer-careers">{total==0 ? <></> : <>({total})</>}</span></a>  </p>
                    </Col>
                    <Col xs={12} md={1} lg={2}  className="footer-privacy">
                        <p className="title-gray"><a href="/privacy/">Privacy</a></p>
                        <p className="title-gray"><a href="/terms/">Terms</a></p> 
                    </Col>
                    <Col  xs={12} md={2} lg={2} className="footer-kosovo" >
                        <p className="title-gray">+383 49 460 555</p>
                        <p className="title-gray"><a href="https://g.page/sogody?share" target="_blank">Rr. Demokracia 20 Prishtinë XK, 10000<br></br> Kosovë</a></p> 
                    </Col>
                    <Col  xs={12} md={2} lg={2} className="footer-london" > 
                        <p className="title-gray">+44 20 8133 3315</p>
                        <p className="title-gray"><a href="https://goo.gl/maps/xGjtQzvTFvikPPbu7" target="_blank">Sydney Road, Sutton, London SM12QL</a></p> 
                    </Col>
                    <Col  xs={12} md={3} lg={2} className="footer-info">
                        <p className="title-gray"> <a href="mailto:info@sogody.com">info@sogody.com</a></p> 
                    </Col>
                    <Col  xs={12} md={2} lg={2} className="footer-social">
                        <p className="title-gray">&copy; {(new Date().getFullYear())} Sogody</p>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/sogodycom/" target="_blank" rel="noreferrer"> <img src={require('../assets/images/social-icons/facebook.svg')}  alt="Logo" /> </a> 
                            <a href="https://www.instagram.com/sogodycom/" target="_blank" rel="noreferrer"> <img src={require('../assets/images/social-icons/instagram.svg')}  alt="Logo" /> </a>
                            <a href="https://www.linkedin.com/company/sogody" target="_blank" rel="noreferrer"> <img src={require('../assets/images/social-icons/linkedin.svg')}  alt="Logo" /> </a> 
                            <a href="https://twitter.com/sogodycom" target="_blank" rel="noreferrer"> <img src={require('../assets/images/social-icons/twitter.svg')}  alt="Logo" /> </a>  
                        </div>
                    </Col>
                </Row>
                <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
        </Container> 
        </div>
    )
}
export const query = graphql`
{
    allContentfulJobPositions(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
            date
        }
        totalCount,
        
    }
}
`
export default Footer
