import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Container, Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';
import '../assets/bootstrap.min.css'
import ContactUs from './ContactUs';
import { useLocation } from "@reach/router"

import arrow from '../assets/images/next_green.svg';

const Header = () => {

    const [modalShow, setModalShow] = useState(false);
    const [showDropdown,setShowDropdown] = useState(false)
    const data = useStaticQuery(query);
    const {
        allContentfulOurServicesHomepageBoxes : {nodes: services }
    } = data

    const {allContentfulJobPositions:{}} = data

    const today = new Date();
    const careers = data.allContentfulJobPositions.nodes;
    const total= careers.filter(careers=> new Date(careers.date) >= today).length;

    const pathLocation= useLocation();
    const pathName= pathLocation.pathname.substring(1).slice(0, -1);
  
    const [menuShow, setMenuShow] = React.useState(false);
    useEffect(() => {
        if (menuShow) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    });

    const [isMobile, setIsMobile] = useState(getScreenSize())

    const handleResize = () => {
        setIsMobile(getScreenSize())
    }

    function getScreenSize() {
        if (typeof window !== "undefined") {
        if (window.matchMedia("(min-width: 992px)").matches) {
            return false
        } else {
            return true
        }
        }
    }

   useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])

    return ( 
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" onMouseLeave={() => {if(!isMobile) setShowDropdown(false)}}>
            <Container>
                <Navbar.Brand href="/">
                    <Image width="auto"
                        height="30"
                        src={require('../assets/images/logo_sogody.png')}
                        alt="Sogody logo"
                    /> 
                </Navbar.Brand> 
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setMenuShow(!menuShow)}/>  
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav> 
                    <Nav className="drop-main"> 
                        <Nav>
                            <a 
                               onMouseEnter={() => { if(!isMobile) setShowDropdown(true)}} 
                               className={`nav-link nav-link-black  ${pathName === "services" ? "servicesclass":""} ${showDropdown ? "dropdown-shown":""}`} 
                               href="/services/"> 
                               Services 
                               <img src={arrow} alt="menu arrow" className="arrow-menu" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowDropdown(!showDropdown)
                                }} />
                            </a>
                        </Nav >
                        <NavDropdown 
                            className={`service-nav`} 
                            title="Services" id="collasible-nav-dropdown" 
                            show={showDropdown}
                        > 
                            {services.map((service) => {
                                return <NavDropdown.Item className={ service.slug } href={`/services/${service.slug}/`} key={service.slug}> 
                                    <div style={{ display: "inline-flex" }}>
                                        <div className="nav-service-logo-ctn"><img className="nav-service-logo" src={service.image.fluid.src } alt="Logo" /></div>
                                        <p className="header-service-title"> { service.title }</p>  
                                    </div > 
                                </NavDropdown.Item>
                            })} 
                        </NavDropdown>
                        <Nav><a className={`nav-link nav-link-black  ${pathName === "work" ? "workclass":""}`} href="/work/"> Work </a></Nav >
                        <Nav><a className={`nav-link nav-link-black  ${pathName === "updates" ? "updateclass":""}`} href="/updates/"> Updates </a></Nav >
                        <Nav><a className={`nav-link nav-link-black  ${pathName === "company" ? "companyclass":""}`} href="/company/"> Company </a></Nav >
                        <Nav><a className={`nav-link nav-link-black  ${pathName === "careers" ? "careersclass":""}`} href="/careers/"> Careers <span className="footer-careers">{total == 0 ? <></> : <> &nbsp;({total})</>}</span> </a></Nav>
                        <Nav className="contact-div">
                            <div className="nav-contact">
                                <p className="contact-join"> Join the conversation by contacting us. </p> 
                                <button className="contact-us-link" onClick={() => setModalShow(true)}>
                                    <p className="c-us"> Contact us </p> 
                                </button > 
                            </div>  
                        </Nav> 
                    </Nav>   
                </Navbar.Collapse> 
                <ContactUs show={modalShow} onHide={() => setModalShow(false)} /> 
            </Container >
        </Navbar>

    )
}

export default Header

export const query = graphql `
{
    allContentfulOurServicesHomepageBoxes(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
        nodes {
            id
            title
            image {
                fluid(quality: 100) {
                    src
                }
            }
            slug
                info {
                    info
                }
        }
    }
    allContentfulJobPositions(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
            date
        }
        totalCount,
        
    }
}
`